@font-face {
  font-family: 'CharlesWright';
  src: url(./../public/CharlesWright-Bold.ttf);
  font-display: swap;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  min-height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Geologica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--md-sys-color-on-primary);
  color: var(--md-sys-color-primary);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Geologica', sans-serif;
  font-weight: 400;
}

input, button, textarea, select, span {
  font-family: 'Geologica', sans-serif;
  font-weight: 400;
}

::placeholder {
  color: var(--md-sys-color-on-background);
  font-size: 1rem;
}

.loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.backdrop {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 0.5);
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: inherit;
}

.map-wrapper > div > div > div > div > div:nth-child(9) > button,
.map-wrapper > div > div > div > div > div:nth-child(12) > div > div > div {
  border-radius: 9999px !important;
}

.map-wrapper > div > div > div > div > div:nth-child(17) > div {
  display: none !important;
}

textarea {
  height: 100px;
  resize: none;
  padding: 1rem;
  border-radius: 16px;
  border: 1px solid transparent;
  outline: none;
  font-size: 1rem;
  font-family: inherit;
  background-color: var(--mui-palette-FilledInput-bg);
  color: var(--md-sys-color-on-secondary-container);
  transition: all 0.2s ease-in-out;
}
textarea:hover {
  border-color: rgba(var(--mui-palette-common-onBackgroundChannel) / var(--mui-opacity-inputUnderline));
  background-color: var(--mui-palette-FilledInput-hoverBg);
}
textarea:focus {
  border-color: rgba(var(--mui-palette-primary-darkChannel) / var(--mui-opacity-inputUnderline));
}
textarea:active {
  border-color: var(--md-sys-color-on-primary-container);
}