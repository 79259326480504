.autocomplete-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem 1rem;
  flex-wrap: wrap;
  
  > input {
    width: 500px;
    background-color: var(--md-sys-color-primary-container);
    color: var(--md-sys-color-on-primary-container);
    border: none;
    outline: none;
    padding: 1rem;
    border-radius: 9999px;
  }
}

@media screen and (min-width: 768px) {
  .autocomplete-container {
    gap: 2rem;
    padding: 2rem;
    flex-wrap: nowrap;
  }
}

.gm-style .gm-style-iw-c, .gm-style .gm-style-iw-tc::after {
  color: #002020;
  font-weight: bold;
}

.pac-container {
  margin-top: 1rem;
  color: var(--md-sys-color-on-primary-container);
  background-color: var(--md-sys-color-primary-container);
  border-radius: 10px;
  padding: 1rem;
  border: none;

  > .pac-item {
    border: none;
    background-color: rgb(0 0 0 / 5%);
    border-radius: 9999px;
    margin-bottom: 0.25rem;
    color: var(--md-sys-color-secondary);
    
    & .pac-item-query {
      color: var(--md-sys-color-on-primary-container);
    }

    &:hover {
      background-color: var(--md-sys-color-on-primary)
    }
  }
}
